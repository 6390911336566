import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {AppState} from "../../core/store/app.reducer";
import {PlansApi} from "./api/plans.api";
import {PlansList} from "../../core/models/plans-list.model";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";
import {CartItem} from "../../core/models/cart-item.model";
import {INewOrderPayload} from "../../core/models/order.model";
import {getPlans, prices} from "../../core/store/user/user.selector";
import {initPlans} from "../../core/store/user/user.actions";
import {IStripeCustomerInfo, IUserLocationInfo, IUserPaymentSystems, IUserPlan} from "../../core/models/user.model";
import {CookieService} from "../../core/services/cookie.service";

@Injectable()
export class PlansFacade {
	/**
	 * Constructor for DashboardFacade
	 * @param {PlansApi} api
	 * @param {Store<AppState>} store
	 * @param cookieService
	 */
	constructor(private api: PlansApi, private store: Store<AppState>, private cookieService: CookieService) {
	}
	
	initPlans(){
		this.store.dispatch(initPlans())
	}
	
	getAllPlansFromApi(): Observable<IUserPlan[]> {
		return this.api.getAllPlans().pipe(map(res => res.data));
	}
	
	getAllPlans(): Observable<IUserPlan[]> {
		return this.store.select(getPlans)
	}
	getIpListForPlan(planId: number): Observable<string[]> {
		return this.api.getIpListForPlan(planId)
		.pipe(
			map(response => response.ip_list)
		)
	}
	
	getPrices() {
		return this.store.select(prices);
	}
	
	addIpToPlan(planId: number, ip: string): Observable<string[]> {
		return this.api.addIpToPlan(planId, ip)
		.pipe(
			map(response => response.ip_list)
		)
	}
	
	removeIpFromPlan(planId: number, ip: string): Observable<string[]> {
		return this.api.removeIpFromPlan(planId, ip)
		.pipe(
			map(response => response.ip_list)
		)
	}
	
	// plansCheckout(total_amount: number, items: CartItem[]): Observable<{payment_link: string}> {
	// 	return this.api.paddlePlansCheckout(total_amount, items);
	// }
	
	newPlanCheckout(payload: INewOrderPayload): Observable<{secured_payload: string, secured_key: string}> {
		return this.api.newPlanFSCheckout(payload);
	}
	
	newPlanFsCheckout(payload: INewOrderPayload): Observable<{secured_payload: string, secured_key: string}> {
		return this.api.newPlanFSCheckout(payload);
	}
	
	newPlanStrpCheckout(payload: INewOrderPayload, paymentMethod: string): Observable<{client_secret: string}> {
		return this.api.newPlanStrpCheckout(payload, paymentMethod);
	}
	
	newPlanStrpCheckoutSession(payload: INewOrderPayload, paymentMethod: string): Observable<any> {
		return this.api.newPlanStrpCheckoutSession(payload, paymentMethod);
	}
	
	newPlanNpCheckout(payload: INewOrderPayload): Observable<{payment_link: string}> {
		return this.api.newPlanNPCheckout(payload);
	}
	
	newPlanCmCheckout(payload: INewOrderPayload): Observable<{payment_link: string}> {
		return this.api.newPlanCmCheckout(payload);
	}
	
	newPlanPaddleCheckout(payload: INewOrderPayload): Observable<{transaction_id: string, customer_id: string}> {
		return this.api.newPlanPaddleCheckout(payload);
	}
	
	newPlanPssnCheckout(payload: INewOrderPayload): Observable<{payment_link: string}> {
		return this.api.newPlanPssnCheckout(payload);
	}
	
	getMinPaymentSum(): Observable<number> {
		return this.api.getMinPaymentSum().pipe(
			map(data => data.result)
		)
	}
	
	getPoolMinPaymentSum(): Observable<{residental: number, datacenter: number, mobile: number, residental_premium: number}> {
		return this.api.getPoolMinPaymentSum().pipe(
			map(data => data.result)
		)
	}
	
	getAvailablePaymentSystems(): Observable<IUserPaymentSystems> {
		return this.api.getAvailablePaymentSystems().pipe(
			map(data => data.result)
		)
	}
	
	getUserLocation(): Observable<IUserLocationInfo> {
		return this.api.getUserLocation().pipe(
			map(data => data.result)
		)
	}
	
	fsPlansCheckout(total_amount: number, items: CartItem[]): Observable<{secured_payload: string, secured_key: string}> {
		const ga_cid = (window as any).clsid.client_id
		const session_id = (window as any).clsid.session_id
		return this.api.fsPlansCheckout(total_amount, items, ga_cid, session_id);
	}
	
	fsPlansSubscriptionCheckout(plan_id: number, amount: number, traffic_count: number, count_limit: number): Observable<{secured_payload: string, secured_key: string}> {
		const ga_cid = (window as any).clsid?.client_id
		const session_id = (window as any).clsid.session_id
		return this.api.fsPlansSubscriptionCheckout(plan_id, amount, traffic_count, count_limit, ga_cid, session_id);
	}
	
	strpPlansCheckout(paymentMethod: string, total_amount: number, items: CartItem[]): Observable<{client_secret: string}> {
		const ga_cid = (window as any).clsid?.client_id
		const session_id = (window as any).clsid.session_id
		return this.api.strpPlansCheckout(paymentMethod, total_amount, items, ga_cid, session_id);
	}
	
	strpPlansSubscriptionCheckout(payment_method: string, plan_id: number, amount: number, traffic_count: number, count_limit: number): Observable<{client_secret: string}> {
		const ga_cid = (window as any).clsid?.client_id
		const session_id = (window as any).clsid.session_id
		return this.api.strpPlansSubscriptionCheckout(payment_method, plan_id, amount, traffic_count, count_limit, ga_cid, session_id);
	}
	
	npPlansCheckout(total_amount: number, items: CartItem[]): Observable<{payment_link: string}> {
		const ga_cid = (window as any).clsid?.client_id
		const session_id = (window as any).clsid.session_id
		return this.api.npPlansCheckout(total_amount, items, ga_cid, session_id);
	}
	
	cmPlansCheckout(total_amount: number, items: CartItem[]): Observable<{payment_link: string}> {
		const ga_cid = (window as any).clsid?.client_id
		const session_id = (window as any).clsid.session_id
		return this.api.cmPlansCheckout(total_amount, items, ga_cid, session_id);
	}
	
	paddlePlansCheckout(total_amount: number, items: CartItem[]): Observable<{transaction_id: string, customer_id: string}> {
		const ga_cid = (window as any).clsid?.client_id
		const session_id = (window as any).clsid.session_id
		return this.api.paddlePlansCheckout(total_amount, items, ga_cid, session_id);
	}
	
	pssnPlansCheckout(total_amount: number, items: CartItem[]): Observable<{payment_link: string}> {
		const ga_cid = (window as any).clsid?.client_id
		const session_id = (window as any).clsid.session_id
		return this.api.pssnPlansCheckout(total_amount, items, ga_cid, session_id);
	}
	
	cancelPlanSubscription(plan_id: number): Observable<any> {
		return this.api.cancelPlanSubscription(plan_id);
	}
	
	checkOrderStatus(orderId: string, paymentSystem: string): Observable<any> {
		return this.api.checkOrderStatus(orderId, paymentSystem);
	}
	
	setOrderGaStatus(orderId: string): Observable<any> {
		return this.api.setOrderGaStatus(orderId);
	}
	
	getStripeCustomerInfo(): Observable<IStripeCustomerInfo> {
		return this.api.getStripeCustomerInfo().pipe(
			map(data => data.data)
		);
	}
	
	updateFbAnalyticsParams() {
		const fbp = this.cookieService.getCookie('_fbp');
		const fbc = this.cookieService.getCookie('_fbc');
		return this.api.updateFbAnalyticsParams(fbp, fbc);
	}
}

import {Component, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {jackInTheBoxOnEnterAnimation} from "angular-animations";
import {AbstractControl, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {RegisterFormModel} from "../../models/register-form.model";
import {BehaviorSubject, Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {AuthFacade} from "../../auth.facade";
import {GaService} from "../../../core/services/ga.service";
import {FingerprintjsProAngularService} from "@fingerprintjs/fingerprintjs-pro-angular";
import {RecaptchaComponent} from "ng-recaptcha";
import {CookieService} from "../../../core/services/cookie.service";
import {getLanguage} from "../../../core/store/view/view.selectors";
import {Store} from "@ngrx/store";
import {AppState} from "../../../core/store/app.reducer";
import {Router} from "@angular/router";

export function MustMatch(controlName: string, checkControlName: string): ValidatorFn {
  return (controls: AbstractControl) => {
    const control = controls.get(controlName);
    const checkControl = controls.get(checkControlName);
    
    if (checkControl?.errors && !checkControl.errors.matching) {
      return null;
    }
    
    if (control?.value !== checkControl?.value) {
      controls.get(checkControlName)?.setErrors({ matching: true });
      return { matching: true };
    } else {
      return null;
    }
  }
}

declare let gtag:Function;
@Component({
  selector: 'app-sign-up-new',
  templateUrl: './sign-up-new.component.html',
  styleUrls: ['./sign-up-new.component.scss'],
  encapsulation : ViewEncapsulation.None,
  animations: [
    jackInTheBoxOnEnterAnimation({duration: 500, delay: 100}),
  ]
})
export class SignUpNewComponent implements OnInit {
  
  @ViewChild('captchaRef') captchaRef!: RecaptchaComponent
  
  form:FormGroup;
  formData!: Partial<RegisterFormModel>;
  
  isShowPass: boolean = false;
  
  selectedMessengerType: string | undefined = undefined;
  messengerTypes: {key: string, label: string}[] = [
    {
      key: 'viber',
      label: 'Viber'
    },
    {
      key: 'signal',
      label: 'Signal'
    },
    {
      key: 'whatsapp',
      label: 'WhatsApp'
    },
    {
      key: 'telegram',
      label: 'Telegram'
    },
    {
      key: 'skype',
      label: 'Skype'
    },
    {
      key: 'email',
      label: 'Email'
    }
  ]
  showMessengerField: boolean = false;
  
  selectedUseCase: string | null = null;
  showOtherUseCaseField$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false)
  useCases: string[] = [
    'Web scraping',
    'SEO scraping',
    'Ad verification',
    'Website testing',
    'Price comparison',
    'Brand protection',
    'Streaming content delivery',
    'Reselling',
    'Affiliate Program',
    'Other'
  ];
  
  isLoading!: Observable<boolean>;
  
  affiliateID: string|null = null;
  
  promoAffiliate: string|null = null;
  
  acceptTerms: FormControl =  new FormControl(false, Validators.requiredTrue)
  
  env = environment;
  
  language: string = 'en';
  
  regSource: string = 'main';
  /**
   * Recaptcha site key
   * @type {string}
   */
  public SITE_ID_NG = environment.recaptchaKey;
  constructor(
      private authFacade: AuthFacade,
      private gaService: GaService,
      private fingerprintService: FingerprintjsProAngularService,
      private cookieService: CookieService,
      private store: Store<AppState>,
      private router: Router
  ) {
    if(this.router.url.includes('sign-up-affiliate')) {
      this.regSource = 'affiliate';
    }
    
    this.form = new FormGroup({
      name: new FormControl(undefined, [
        Validators.required,
      ]),
      email: new FormControl(undefined, [
        Validators.required,
        Validators.email,
      ]),
      password: new FormControl(undefined, [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(1000)
      ]),
      passwordConfirm: new FormControl(undefined, [
      
      ]),
      company: new FormControl(undefined),
      phone: new FormControl(undefined),
      messenger_type: new FormControl(undefined, Validators.required),
      messenger_account: new FormControl(undefined, Validators.nullValidator),
      use_case: new FormControl((this.regSource === 'affiliate' ? 'Affiliate Program' : undefined), Validators.required),
      // use_case: new FormControl(undefined, Validators.required),
      use_case_custom: new FormControl(undefined, [Validators.nullValidator, Validators.maxLength(250)] )
      
    }, {validators: MustMatch('password', 'passwordConfirm')})
  
    this.store.select(getLanguage).subscribe(lng => {
      this.language = lng ?? 'en';
    })
  }

  ngOnInit(): void {
    this.affiliateID = (localStorage.getItem('affiliateID')) ?
        localStorage.getItem('affiliateID') : this.cookieService.getCookie('aff_id');
    this.promoAffiliate = (localStorage.getItem('promoAffiliate')) ?
        localStorage.getItem('promoAffiliate') : this.cookieService.getCookie('promo_aff');
    
    this.isLoading = this.authFacade.getLoadingState();
    this.getGtagParams();
  }
  
  getGtagParams() {
    (window as any).clsid = {
      client_id: '',
      session_id: ''
    };
    try {
      gtag('get', environment.measurementId, 'client_id', function(r: any) {
        (window as any).clsid.client_id = r
      });
    } catch (e) {
      console.warn(e)
    }
    try {
      gtag('get', environment.measurementId, 'session_id', function(r: any) {
        (window as any).clsid.session_id = r
      });
    } catch (e) {
      console.warn(e)
    }
  }
  
  async completeRegister(captcha: string): Promise<void> {
    if ( this.form.valid && this.acceptTerms.valid) {
      const formData = {...this.form.value} as RegisterFormModel
      if (formData.use_case === 'Other') {
        formData.use_case = this.getFormControl('use_case_custom')?.value
      }
      let affiliate_id = this.affiliateID !== null ? this.affiliateID : '';
      let promo_affiliate = this.promoAffiliate !== null ? this.promoAffiliate : '';
      const ga_cid = this.gaService.getGaCid();
      const g_session_id = (window as any).clsid.session_id;
      const gclid = this.cookieService.getCookie('gclid');
      let utm_source = this.cookieService.getCookie('utm_source');
      let utm_campaign = this.cookieService.getCookie('utm_campaign');
      let utm_term = this.cookieService.getCookie('utm_term');
      let utm_content = this.cookieService.getCookie('utm_content');
      let utm_medium = this.cookieService.getCookie('utm_medium');
      
      if(ga_cid &&
        (gclid === null || gclid === undefined || gclid === '')
        && (utm_source === null || utm_source === undefined || utm_source === '')) {
        utm_source = 'none';
        utm_campaign = 'none';
        utm_term = 'none';
        utm_content = 'none';
        utm_medium = 'none';
        if (document.referrer && document.referrer.length > 1){
          let r = document.referrer;
          r = r.replace(/(https?:\/\/)?(www.)?/i, '');
          if (r.indexOf('/') !== -1) {
            r = r.split('/')[0];
          }
          
          if(r.indexOf('google.') !== -1) {
            r = 'google';
          }
          if(r.indexOf('bing.') !== -1) {
            r = 'bing';
          }
          if(r.indexOf('yahoo.') !== -1) {
            r = 'yahoo';
          }
          
          utm_source = r;
          utm_medium = 'referral';
          if(utm_source === 'google' || utm_source === 'bing' || utm_source === 'yahoo') {
            utm_medium = 'organic';
          } else if(document.referrer.indexOf('dataimpulse.com') !== -1) {
            utm_medium = 'none';
            utm_source = 'direct';
          }
          
        } else {
          utm_source = 'direct';
        }
      }
      
      const fbp = this.cookieService.getCookie('_fbp');
      const fbc = this.cookieService.getCookie('_fbc');
  
      let visitor_id;
      try {
        const data = await this.fingerprintService.getVisitorData();
        visitor_id = data.visitorId;
      } catch (e) {
      } finally {
      }
      const payload = {
        ...formData,
        recaptcha: captcha,
        affiliate_id,
        promo_affiliate,
        gaCid: ga_cid,
        visitorId: visitor_id,
        g_session_id,
        language: this.language,
        utm_params: {
          gclid: gclid,
          utm_source: utm_source,
          utm_campaign: utm_campaign,
          utm_term: utm_term,
          utm_content: utm_content,
          utm_medium: utm_medium,
          g_session_id: g_session_id,
          fbp: fbp,
          fbc: fbc,
        },
      }
      this.authFacade.registerUser(payload)
      
    }
    this.captchaRef?.reset();
    
  }
  
  useCaseChange(useCase: string) {
    if (useCase === 'Other') {
      this.showOtherUseCaseField$.next(true)
      this.getFormControl('use_case_custom')?.setValidators([
        Validators.required,
        Validators.minLength(4)
      ])
      return;
    }
    this.getFormControl('use_case_custom')?.setValidators([
      Validators.nullValidator
    ])
    this.showOtherUseCaseField$.next(false);
    return;
  }
  
  changeMessenger(messenger: string) : void {
    this.form.patchValue({messenger_account: null})
    switch (messenger) {
      case 'viber':
      case 'whatsapp':
      case 'signal':
        this.getFormControl('messenger_account')?.setValidators([
          Validators.required,
          Validators.pattern('[- +()0-9]+'),
          Validators.minLength(5)
        ])
        this.form.patchValue({messenger_account: '+'})
        break;
      case 'telegram':
        this.getFormControl('messenger_account')?.setValidators([
          Validators.required,
          Validators.minLength(5)
        ])
        this.form.patchValue({messenger_account: '@'})
      break;
      case 'skype':
        this.getFormControl('messenger_account')?.setValidators([
          Validators.required,
          Validators.minLength(5)
        ])
        break;
      case 'email':
        this.form.patchValue({messenger_account: this.getFormControl('email')?.value})
      break;
    }
    this.showMessengerField = messenger !== 'email';
    
  }
  
  /**
   * Getter for form confirm password control
   * @type {AbstractControl}
   */
  getFormControl(name: string): AbstractControl | null {
    return this.form.get(name);
  }
  
  showPass(e: any) {
    this.isShowPass = !this.isShowPass;
  }
  
  messengerChange(event: Event) {
    const messenger = this.getFormControl('messenger_type')?.value;
    if (!messenger) return;
    const target: HTMLInputElement = event.target as HTMLInputElement;
    switch (messenger) {
      case 'viber':
      case 'whatsapp':
      case 'signal':
        if (!target.value) this.form.patchValue({messenger_account: '+'})
        break;
      case 'telegram':
        if (!target.value) this.form.patchValue({messenger_account: '@'})
        break;
    }
    
  }
  
  goToThirdParty(provider: string) {
    this.authFacade.goToThirdParty(provider).subscribe((url) => {
      this.cookieService.setCookie('reg_source', this.regSource, 300)
      window.location.href = url;
    })
  }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'poolTypeName'
})
export class PoolTypeNamePipe implements PipeTransform {

  transform(value: string): string {
      switch (value) {
        case 'residental' :
          return 'Residential';
        case 'datacenter' :
          return 'Datacenter';
        case 'mobile' :
          return 'Mobile';
        case 'residental_premium' :
          return 'Residential Premium';
        default :
          return '';
      }
  }

}
